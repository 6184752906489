<template>
    <section id="FleetIndexSection">
        <vue-headful title="FLPN Aviasolutions - List Fleets"></vue-headful>
        <h1 class="title">Fleets</h1>
        <contenedor>
          <form action="#" autocomplete="off" v-on:submit.prevent="paginate">
            <div class="columns" style="margin-bottom:10px;">
              <div class="column is-2">
                <label class="label">Fleet Name</label>
                <b-input v-model="filter.name"></b-input>
              </div>
              <div class="column is-2" style="margin-bottom:10px;">
                <PaginationResults v-model="pagination.perPage"></PaginationResults>
              </div>
              <div class="column is-2">
                <label class="label">&nbsp;</label>
                <button type="submit" class="button is-link is-fullwidth">
                                        <span class="icon">
                                        <i class="fas fa-filter"></i>
                                        </span>
                  <span class="has-text-weight-bold is-uppercase">Filter Fleets</span>
                </button>
              </div>
              <div class="column is-3">
              </div>
              <div class="column is-3 is-fullwidth" v-if="$auth.check(['admin'])">
                <label class="label">&nbsp;</label>
                <router-link :to="'/fleets/create'" class="button is-link is-fullwidth">
                                        <span class="icon">
                                        <i class="fas fa-plus"></i>
                                        </span>
                  <span class="has-text-weight-bold is-uppercase">Create a new Fleet</span>
                </router-link>
              </div>
            </div>
          </form>
          <b-table
              paginated
              backend-pagination
              backend-sorting
              :hoverable="true"
              :data="pagination.records"
              :loading="pagination.loading"
              :total="pagination.total"
              :per-page="pagination.perPage"
              :default-sort-direction="pagination.defaultSortOrder"
              :default-sort="[pagination.sortField, pagination.sortOrder]"
              :row-class="(row, index) => row.is_selected === true && 'is-info'"
              @page-change="onPageChange"
              @sort="onSort">
            <template slot-scope="props">
              <b-table-column field="created_at" label="Name" sortable>
                <span>{{ props.row.name }}</span>
              </b-table-column>
              <b-table-column field="taxi_in" label="Taxi In" sortable numeric>
                <span>{{ props.row.taxi_in}}</span>
              </b-table-column>
              <b-table-column field="taxi_out" label="Taxi Out" sortable numeric>
                <span>{{ props.row.taxi_out}}</span>
              </b-table-column>
              <b-table-column field="min_fuel_wt" label="Minimum Fuel" sortable numeric>
                <span>{{ props.row.min_fuel_wt}}</span>
              </b-table-column>
              <b-table-column field="contingency_weight" label="Contingency Weight" sortable numeric>
                <span>{{ props.row.contingency_weight}}</span>
              </b-table-column>
              <b-table-column field="contingency_pct" label="Contingency Percent" sortable numeric>
                <span>{{ props.row.contingency_pct}} %</span>
              </b-table-column>
                <b-table-column field="contingency_time" label="Contingency Time" sortable numeric>
                    <span>{{ props.row.contingency_time}}</span>
                </b-table-column>
              <b-table-column field="weight_unit" label="Weight Unit" sortable centered>
                <span>{{ props.row.weight_unit}}</span>
              </b-table-column>
              <b-table-column field="client_name" label="Client" v-if="$auth.check(['admin'])">
                <span>{{ props.row.client.name}}</span>
              </b-table-column>
              <b-table-column width="80">
                <div class="field has-addons">
                  <p class="control">
                    <router-link :to="`/fleets/${props.row.id}/edit`" class="button is-small is-link is-success">
                      EDIT
                    </router-link>
                  </p>
                </div>
              </b-table-column>
            </template>
          </b-table>
        </contenedor>

        <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false"></b-loading>
    </section>
</template>
<script>
import FleetService from "../../services/fleets.service.js";
import FleetsPaginationFilter from "../../filters/fleets-pagination.filter";
import Contenedor from "@/components/Contenedor";
import PaginationResults from "@/components/shared/PaginationResults";
export default {
    name: "FleetsIndex",
  components: {PaginationResults, Contenedor},
  data() {
        return {
            isLoading: false,
            fleetsService: new FleetService(),
            pagination: {
                records: [],
                total: 0,
                loading: false,
                sortField: "id",
                sortOrder: "desc",
                defaultSortOrder: "desc",
                page: 1,
                perPage: 20
            },
            filter: new FleetsPaginationFilter()
        };
    },
    methods: {
        paginate() {
            const params = {
                sort_field: this.pagination.sortField,
                sort_order: this.pagination.sortOrder,
                per_page: this.pagination.perPage,
                page: this.pagination.page
            };
            if (!this.filter.isEmpty()) {
                params.filter = this.generateFilters();
            }
            this.pagination.loading = true;
            this.fleetsService.paginate(params).then(
                ({data}) => {
                    this.pagination.records = data.data;                    
                    this.pagination.total = data.meta.total;                    
                    this.pagination.loading = false
                },
                () => {
                    this.pagination.records = []
                    this.pagination.total = 0
                    this.pagination.loading = false
                    this.$buefy.toast.open({
                        message: 'An error ocurred while paginating!!!',
                        position: 'is-bottom',
                        type: 'is-danger'
                    });
                }
            );
        },
        generateFilters() {
            var filtro = {};
            if (this.filter.hasValue(this.filter.name)) {
                filtro.name = this.filter.name;
            }
            return filtro;
        },
        /*
        * Handle page-change event
        */
        onPageChange(page) {
            this.pagination.page = page;
            this.paginate();
        },
        /*
        * Handle sort event
        */
        onSort(field, order) {
            this.pagination.sortField = field;
            this.pagination.sortOrder = order;
            this.paginate();
        }
    },
    mounted() {
        this.paginate();
    },
};
</script>
<style>
    tr.is-info {
        background: #167df0;
        color: #fff;
    }
    #FleetIndexSection .card-header {
      background-color: lightgrey;
    }
</style>